@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import './scss/product-images.scss';
body {
  margin: 0;
  .list-group {
    list-style: none;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    margin-bottom: 20px;
    padding-left: 0;
    &.header {
      margin-bottom: 0;
      .list-group-item {
        font-weight: bold;
        border-top: none;
      }
    }
    &.header, &.sortable {
      box-shadow: none;
      .list-group-item {
        border-left: none;
        border-right: none;
        border-radius: 0;
        word-break: break-all;
      }
    }
    &:not(.header) .list-group-item:hover { background-color: #f5f5f5; }
    .list-group-item {
      // position: relative;
      display: block;
      padding: 10px 15px;
      margin-bottom: -1px;
      background-color: #fff;
      border: 1px solid #ddd;
    }
  }
}

@font-face {
  font-family: 'Glyphicons Regular';
  src: local('Glyphicons Regular'),
       url("./fonts/Glyphicon/glyphicons-regular.eot") format("truetype");
  src: local('Glyphicons Regular'),
       url("./fonts/Glyphicon/glyphicons-regular.eot?#iefix") format('embedded-opentype'),
       url("./fonts/Glyphicon/glyphicons-regular.woff2") format('woff2'),
       url("./fonts/Glyphicon/glyphicons-regular.woff") format('woff'),
       url("./fonts/Glyphicon/glyphicons-regular.ttf") format('truetype'),
       url("./fonts/Glyphicon/glyphicons-regular.svg") format('svg')
}

// Catchall baseclass
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Regular';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Individual icons
.glyphicon-asterisk               { &:before { content: "\e249"; } }
.glyphicon-plus                   { &:before { content: "\e433"; } }
.glyphicon-euro                   { &:before { content: "\e227"; } }
.glyphicon-minus                  { &:before { content: "\e434"; } }
.glyphicon-cloud                  { &:before { content: "\e233"; } }
.glyphicon-envelope               { &:before { content: "\e011"; } }
.glyphicon-pencil                 { &:before { content: "\e031"; } }
.glyphicon-glass                  { &:before { content: "\e001"; } }
.glyphicon-music                  { &:before { content: "\e018"; } }
.glyphicon-search                 { &:before { content: "\e028"; } }
.glyphicon-heart                  { &:before { content: "\e013"; } }
.glyphicon-star                   { &:before { content: "\e050"; } }
.glyphicon-star-empty             { &:before { content: "\e049"; } }
.glyphicon-user                   { &:before { content: "\e004"; } }
.glyphicon-film                   { &:before { content: "\e009"; } }
.glyphicon-th-large               { &:before { content: "\e010"; } }
.glyphicon-th                     { &:before { content: "\e157"; } }
.glyphicon-th-list                { &:before { content: "\e158"; } }
.glyphicon-ok                     { &:before { content: "\e207"; } }
.glyphicon-remove                 { &:before { content: "\e208"; } }
.glyphicon-zoom-in                { &:before { content: "\e237"; } }
.glyphicon-zoom-out               { &:before { content: "\e238"; } }
.glyphicon-off                    { &:before { content: "\e064"; } }
.glyphicon-signal                 { &:before { content: "\e080"; } }
.glyphicon-cog                    { &:before { content: "\e137"; } }
.glyphicon-trash                  { &:before { content: "\e017"; } }
.glyphicon-home                   { &:before { content: "\e021"; } }
.glyphicon-file                   { &:before { content: "\e037"; } }
.glyphicon-time                   { &:before { content: "\e055"; } }
.glyphicon-road                   { &:before { content: "\e027"; } }
.glyphicon-download-alt           { &:before { content: "\e415"; } }
.glyphicon-download               { &:before { content: "\e201"; } }
.glyphicon-upload                 { &:before { content: "\e202"; } }
.glyphicon-inbox                  { &:before { content: "\e131"; } }
.glyphicon-play-circle            { &:before { content: "\e221"; } }
.glyphicon-repeat                 { &:before { content: "\e086"; } }
.glyphicon-refresh                { &:before { content: "\e082"; } }
.glyphicon-list-alt               { &:before { content: "\e040"; } }
.glyphicon-lock                   { &:before { content: "\e204"; } }
.glyphicon-flag                   { &:before { content: "\e267"; } }
.glyphicon-headphones             { &:before { content: "\e077"; } }
.glyphicon-volume-off             { &:before { content: "\e183"; } }
.glyphicon-volume-down            { &:before { content: "\e184"; } }
.glyphicon-volume-up              { &:before { content: "\e185"; } }
.glyphicon-qrcode                 { &:before { content: "\e039"; } }
.glyphicon-barcode                { &:before { content: "\e259"; } }
.glyphicon-tag                    { &:before { content: "\e066"; } }
.glyphicon-tags                   { &:before { content: "\e067"; } }
.glyphicon-book                   { &:before { content: "\e072"; } }
.glyphicon-bookmark               { &:before { content: "\e073"; } }
.glyphicon-print                  { &:before { content: "\e016"; } }
.glyphicon-camera                 { &:before { content: "\e011"; } }
.glyphicon-font                   { &:before { content: "\e101"; } }
.glyphicon-bold                   { &:before { content: "\e103"; } }
.glyphicon-italic                 { &:before { content: "\e102"; } }
.glyphicon-text-height            { &:before { content: "\e106"; } }
.glyphicon-text-width             { &:before { content: "\e107"; } }
.glyphicon-align-left             { &:before { content: "\e111"; } }
.glyphicon-align-center           { &:before { content: "\e112"; } }
.glyphicon-align-right            { &:before { content: "\e113"; } }
.glyphicon-align-justify          { &:before { content: "\e114"; } }
.glyphicon-list                   { &:before { content: "\e115"; } }
.glyphicon-indent-left            { &:before { content: "\e109"; } }
.glyphicon-indent-right           { &:before { content: "\e110"; } }
.glyphicon-facetime-video         { &:before { content: "\e181"; } }
.glyphicon-picture                { &:before { content: "\e139"; } }
.glyphicon-map-marker             { &:before { content: "\e243"; } }
.glyphicon-adjust                 { &:before { content: "\e092"; } }
.glyphicon-tint                   { &:before { content: "\e093"; } }
.glyphicon-edit                   { &:before { content: "\e151"; } }
.glyphicon-share                  { &:before { content: "\e152"; } }
.glyphicon-check                  { &:before { content: "\e153"; } }
.glyphicon-move                   { &:before { content: "\e187"; } }
.glyphicon-step-backward          { &:before { content: "\e171"; } }
.glyphicon-fast-backward          { &:before { content: "\e172"; } }
.glyphicon-backward               { &:before { content: "\e173"; } }
.glyphicon-play                   { &:before { content: "\e174"; } }
.glyphicon-pause                  { &:before { content: "\e175"; } }
.glyphicon-stop                   { &:before { content: "\e176"; } }
.glyphicon-forward                { &:before { content: "\e177"; } }
.glyphicon-fast-forward           { &:before { content: "\e178"; } }
.glyphicon-step-forward           { &:before { content: "\e179"; } }
.glyphicon-eject                  { &:before { content: "\e180"; } }
.glyphicon-chevron-left           { &:before { content: "\e225"; } }
.glyphicon-chevron-right          { &:before { content: "\e224"; } }
.glyphicon-plus-sign              { &:before { content: "\e191"; } }
.glyphicon-minus-sign             { &:before { content: "\e192"; } }
.glyphicon-remove-sign            { &:before { content: "\e193"; } }
.glyphicon-ok-sign                { &:before { content: "\e194"; } }
.glyphicon-question-sign          { &:before { content: "\e195"; } }
.glyphicon-info-sign              { &:before { content: "\e196"; } }
.glyphicon-screenshot             { &:before { content: "\e186"; } }
.glyphicon-remove-circle          { &:before { content: "\e198"; } }
.glyphicon-ok-circle              { &:before { content: "\e199"; } }
.glyphicon-ban-circle             { &:before { content: "\e200"; } }
.glyphicon-arrow-left             { &:before { content: "\e211"; } }
.glyphicon-arrow-right            { &:before { content: "\e212"; } }
.glyphicon-arrow-up               { &:before { content: "\e214"; } }
.glyphicon-arrow-down             { &:before { content: "\e213"; } }
.glyphicon-share-alt              { &:before { content: "\e223"; } }
.glyphicon-resize-full            { &:before { content: "\e216"; } }
.glyphicon-resize-small           { &:before { content: "\e215"; } }
.glyphicon-exclamation-sign       { &:before { content: "\e197"; } }
.glyphicon-gift                   { &:before { content: "\e070"; } }
.glyphicon-leaf                   { &:before { content: "\e002"; } }
.glyphicon-fire                   { &:before { content: "\e023"; } }
.glyphicon-eye-open               { &:before { content: "\e052"; } }
.glyphicon-eye-close              { &:before { content: "\e053"; } }
.glyphicon-warning-sign           { &:before { content: "\e079"; } }
.glyphicon-plane                  { &:before { content: "\2708"; } }
.glyphicon-calendar               { &:before { content: "\e046"; } }
.glyphicon-random                 { &:before { content: "\e084"; } }
.glyphicon-comment                { &:before { content: "\e310"; } }
.glyphicon-magnet                 { &:before { content: "\e024"; } }
.glyphicon-chevron-up             { &:before { content: "\e113"; } }
.glyphicon-chevron-down           { &:before { content: "\e114"; } }
.glyphicon-retweet                { &:before { content: "\e230"; } }
.glyphicon-shopping-cart          { &:before { content: "\e203"; } }
.glyphicon-folder-close           { &:before { content: "\e441"; } }
.glyphicon-folder-open            { &:before { content: "\e145"; } }
.glyphicon-resize-vertical        { &:before { content: "\e119"; } }
.glyphicon-resize-horizontal      { &:before { content: "\e120"; } }
.glyphicon-hdd                    { &:before { content: "\e343"; } }
.glyphicon-bullhorn               { &:before { content: "\e356"; } }
.glyphicon-bell                   { &:before { content: "\e334"; } }
.glyphicon-certificate            { &:before { content: "\e333"; } }
.glyphicon-thumbs-up              { &:before { content: "\e344"; } }
.glyphicon-thumbs-down            { &:before { content: "\e345"; } }
.glyphicon-hand-right             { &:before { content: "\e346"; } }
.glyphicon-hand-left              { &:before { content: "\e347"; } }
.glyphicon-hand-up                { &:before { content: "\e348"; } }
.glyphicon-hand-down              { &:before { content: "\e349"; } }
.glyphicon-circle-arrow-right     { &:before { content: "\e218"; } }
.glyphicon-circle-arrow-left      { &:before { content: "\e217"; } }
.glyphicon-circle-arrow-up        { &:before { content: "\e219"; } }
.glyphicon-circle-arrow-down      { &:before { content: "\e220"; } }
.glyphicon-globe                  { &:before { content: "\e371"; } }
.glyphicon-wrench                 { &:before { content: "\e440"; } }
.glyphicon-tasks                  { &:before { content: "\e137"; } }
.glyphicon-filter                 { &:before { content: "\e321"; } }
.glyphicon-briefcase              { &:before { content: "\e342"; } }
.glyphicon-fullscreen             { &:before { content: "\e350"; } }
.glyphicon-dashboard              { &:before { content: "\e332"; } }
.glyphicon-paperclip              { &:before { content: "\e063"; } }
.glyphicon-heart-empty            { &:before { content: "\e020"; } }
.glyphicon-link                   { &:before { content: "\e051"; } }
.glyphicon-phone                  { &:before { content: "\e164"; } }
.glyphicon-pushpin                { &:before { content: "\e336"; } }
.glyphicon-usd                    { &:before { content: "\e228"; } }
.glyphicon-gbp                    { &:before { content: "\e229"; } }
.glyphicon-sort                   { &:before { content: "\e404"; } }
.glyphicon-sort-by-alphabet       { &:before { content: "\e405"; } }
.glyphicon-sort-by-alphabet-alt   { &:before { content: "\e406"; } }
.glyphicon-sort-by-order          { &:before { content: "\e407"; } }
.glyphicon-sort-by-order-alt      { &:before { content: "\e408"; } }
.glyphicon-sort-by-attributes     { &:before { content: "\e409"; } }
.glyphicon-sort-by-attributes-alt { &:before { content: "\e410"; } }
.glyphicon-unchecked              { &:before { content: "\e154"; } }
.glyphicon-expand                 { &:before { content: "\e369"; } }
.glyphicon-expand-down            { &:before { content: "\e368"; } }
.glyphicon-collapse-up            { &:before { content: "\e370"; } }
.glyphicon-log-in                 { &:before { content: "\e387"; } }
.glyphicon-flash                  { &:before { content: "\e242"; } }
.glyphicon-log-out                { &:before { content: "\e388"; } }
.glyphicon-new-window             { &:before { content: "\e390"; } }
.glyphicon-record                 { &:before { content: "\e170"; } }
.glyphicon-save                   { &:before { content: "\e415"; } }
.glyphicon-open                   { &:before { content: "\e416"; } }
.glyphicon-saved                  { &:before { content: "\e417"; } }
.glyphicon-import                 { &:before { content: "\e419"; } }
.glyphicon-export                 { &:before { content: "\e420"; } }
.glyphicon-send                   { &:before { content: "\e422"; } }
.glyphicon-floppy-disk            { &:before { content: "\e444"; } }
.glyphicon-floppy-saved           { &:before { content: "\e445"; } }
.glyphicon-floppy-remove          { &:before { content: "\e446"; } }
.glyphicon-floppy-save            { &:before { content: "\e447"; } }
.glyphicon-floppy-open            { &:before { content: "\e448"; } }
.glyphicon-credit-card            { &:before { content: "\e268"; } }
.glyphicon-transfer               { &:before { content: "\e458"; } }
.glyphicon-cutlery                { &:before { content: "\e277"; } }
.glyphicon-header                 { &:before { content: "\e460"; } }
.glyphicon-compressed             { &:before { content: "\e411"; } }
.glyphicon-earphone               { &:before { content: "\e443"; } }
.glyphicon-phone-alt              { &:before { content: "\e442"; } }
.glyphicon-tower                  { &:before { content: "\e421"; } }
.glyphicon-stats                  { &:before { content: "\e041"; } }
.glyphicon-sd-video               { &:before { content: "\e391"; } }
.glyphicon-hd-video               { &:before { content: "\e392"; } }
.glyphicon-subtitles              { &:before { content: "\e393"; } }
.glyphicon-sound-stereo           { &:before { content: "\e394"; } }
.glyphicon-sound-dolby            { &:before { content: "\e395"; } }
.glyphicon-sound-5-1              { &:before { content: "\e396"; } }
.glyphicon-sound-6-1              { &:before { content: "\e397"; } }
.glyphicon-sound-7-1              { &:before { content: "\e398"; } }
.glyphicon-copyright-mark         { &:before { content: "\e399"; } }
.glyphicon-registration-mark      { &:before { content: "\e400"; } }
.glyphicon-cloud-download         { &:before { content: "\e364"; } }
.glyphicon-cloud-upload           { &:before { content: "\e365"; } }
.glyphicon-tree-conifer           { &:before { content: "\e317"; } }
.glyphicon-tree-deciduous         { &:before { content: "\e318"; } }
.glyphicon-group                  { &:before { content: "\e044"; } }
.glyphicon-menu-down              { &:before { content: "\e113"; } }
.glyphicon-shield                 { &:before { content: "\e271"; } }
.glyphicon-cup                    { &:before { content: "\e075"; } }
.glyphicon-book-open              { &:before { content: "\e352"; } }
.glyphicon-hash                   { &:before { content: "\e750"; } }
.glyphicon-scissors               { &:before { content: "\e289"; } }
